var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Items',{attrs:{"bladeName":"batch-tracking","bladesData":_vm.bladesData,"canSearch":false,"canSelect":false,"customURL":"/Recall","getParams":function (d) { return { id: d.data.id };},"headers":[
        { text: 'From', value: 'stockTransferOrder.departureLocationID', navigation: 'public-locations', textFilter: 'toFamiliarLocationLine', csvFilter: 'toLocationLineNoCommas', single: true, useLocalCache: true, csv: true },
        { text: 'To', value: 'stockTransferOrder.destinationLocationID', navigation: 'public-locations', textFilter: 'toFamiliarLocationLine', csvFilter: 'toLocationLineNoCommas', single: true, useLocalCache: true, csv: true },
        { text: 'Customer', value: 'stockTransferOrder.buyerID', navigation: 'public-companies', itemText: 'companyName', single: true, useLocalCache: true, csv: true },
        { text: 'Quantity', value: 'quantity', csv: true },
        { text: 'Transferred On', value: 'stockTransferOrder.performedOn', textFilter: 'toShortDate', csv: true },
        { text: 'Packed By', value: 'packedByUserID', navigation: 'public-users', single: true, useLocalCache: true, csv: true }
    ],"hideActions":"","hideFooter":false,"navigation":"batch-archives","onFilter":_vm.filterItems,"title":"Batch Tracking","showFilters":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.showTransfers = !_vm.showTransfers}}},[_vm._v(_vm._s(_vm.showTransfers ? 'Show Last Legs' : 'Show Internal Transfers'))])]},proxy:true},{key:"packedBy",fn:function(ref){
    var item = ref.item;
return [_c('BT-Entity',{attrs:{"itemValue":item.packedByUserID,"itemText":"userName","navigation":"users","single":""}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }