<template>
    <BT-Blade-Items
        bladeName="batch-tracking"
        :bladesData="bladesData"
        :canSearch="false"
        :canSelect="false"
        customURL="/Recall"
        :getParams="d => { return { id: d.data.id };}"
        :headers="[
            { text: 'From', value: 'stockTransferOrder.departureLocationID', navigation: 'public-locations', textFilter: 'toFamiliarLocationLine', csvFilter: 'toLocationLineNoCommas', single: true, useLocalCache: true, csv: true },
            { text: 'To', value: 'stockTransferOrder.destinationLocationID', navigation: 'public-locations', textFilter: 'toFamiliarLocationLine', csvFilter: 'toLocationLineNoCommas', single: true, useLocalCache: true, csv: true },
            { text: 'Customer', value: 'stockTransferOrder.buyerID', navigation: 'public-companies', itemText: 'companyName', single: true, useLocalCache: true, csv: true },
            { text: 'Quantity', value: 'quantity', csv: true },
            { text: 'Transferred On', value: 'stockTransferOrder.performedOn', textFilter: 'toShortDate', csv: true },
            { text: 'Packed By', value: 'packedByUserID', navigation: 'public-users', single: true, useLocalCache: true, csv: true }
        ]"
        hideActions
        :hideFooter="false"
        navigation="batch-archives"
        :onFilter="filterItems"
        title="Batch Tracking"
        :showFilters="false">
        <template v-slot:actions>
            <v-spacer />
            <v-btn @click="showTransfers = !showTransfers" small>{{ showTransfers ? 'Show Last Legs' : 'Show Internal Transfers' }}</v-btn>
        </template>
        <!-- <template v-slot:buyerID="{ item }">
            <BT-Entity
                entityProp="buyerID"
                :itemProperties="['ID', 'BuyerID', 'Buyer', 'SupplyAgreementTemplateID']"
                :itemValue="item.stockTransferOrder.buyerID"
                itemText="buyer.companyName"
                navigation="customers" />
        </template> -->
        <template v-slot:packedBy="{ item }">
            <BT-Entity
                :itemValue="item.packedByUserID"
                itemText="userName"
                navigation="users"
                single />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Batch-Tracking',
    data() {
        return {
            showTransfers: false
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        filterItems(res) {
            return this.showTransfers ? res.filter(z => z.stockTransferOrder.isTransfer) : res.filter(z => !z.stockTransferOrder.isTransfer);
        },
        getP(bData) {
            return { id: bData.data.id };
        }
    }
}
</script>